import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import VuetablePaginationDropdown from 'vuetable-2/src/components/VuetablePaginationDropdown'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import vSelect from 'vue-select'
import axios from "axios";
import $ from 'jquery'
//import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import DualListBox from "dual-listbox-vue";

export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin,
        VuetablePaginationDropdown,
        VuetablePaginationInfo,
        vSelect,
        axios,
        Datepicker,
        DualListBox
    },
    data: function() {
        return {
            pageTitle: "Brouillard",
            base_url: Vue.BASE_URL,
            moreParams: {},
            crudmodaltitle: "",
            filtremodaltitle: "",
            listeCompte: [],
            plan_comptable_id: "",
            filtretexte : "",
            montant_total_debit: 0,
            montant_total_credit: 0,

            crudform: {
                date: "",
                debit: "",
                credit: "",
                libelle: "",
                description: "",
                montant: "",
                plan_comptable_id: "",
            },
            filtreDate: {
                dateDebut:"",
                dateFin:""
            },
            fields: [
                {
                    name: 'date',
                    title: 'Date',
                    width: "100px",
                    sortable: true,
                    titleClass: "text-center",
                    formatter: (value) => { 
                      return (value === null)
                        ? ''
                        : moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    }
                },
                // {
                //     name: 'code_journaux',
                //     title: 'Code J.',
                //     sortable: false,
                //     width: "90px",
                //     dataClass: "text-center text-bold",
                //     titleClass: "text-center"
                // },
                {
                    name: 'num_ecriture_cd',
                    title: 'N° Ecriture',
                    sortable: false,
                    width: "90px",
                    dataClass: "text-center text-bold bg-yellow text-danger",
                    titleClass: "text-center"
                },
                {
                    name: 'compte',
                    title: 'Compte',
                    sortable: false,
                    width: "120px",
                    dataClass: "text-center text-bold",
                    titleClass: "text-center"
                },
                // {
                //     name: 'description',
                //     title: 'Déscription',
                //     sortable: false
                // },
                {
                    name: 'compte_aux',
                    title: 'Tiers/Auxiliaire',
                    sortable: false,
                    dataClass: "text-center text-bold",
                    titleClass: "text-center"
                },
                {
                    name: 'libelle',
                    title: 'Libellé',
                    sortable: false
                },
                {
                    name: 'montant_debit',
                    title: 'Débit',
                    sortable: false,
                    width: "150px",
                    dataClass: "text-right text-bold text-danger",
                    titleClass: "text-right"
                },
                {
                    name: 'montant_credit',
                    title: 'Crédit',
                    sortable: false,
                    width: "150px",
                    dataClass: "text-right text-bold text-success",
                    titleClass: "text-right"
                },
                {
                    name: 'actions',
                    title: '-',
                    width: "50px",
                    dataClass: "text-center"
                }
            ],
            sortOrder: [
                { field: 'num_ecriture', direction: 'asc' }
            ],
            css: {
                table: {
                    tableClass: 'table table-striped table-bordered table-hover',
                    loadingClass: 'loading',
                    ascendingIcon: 'fas fa-chevron-up',
                    descendingIcon: 'fas fa-chevron-down',
                    handleIcon: 'fas fa-spinner',
                },
                pagination: {
                    infoClass: 'pull-left ',
                    wrapperClass: 'vuetable-pagination text-center',
                    activeClass: 'btn-secondary',
                    disabledClass: 'disabled',
                    pageClass: 'btn btn-border',
                    linkClass: 'btn btn-border',
                    icons: {
                        first: '',
                        prev: '',
                        next: '',
                        last: '',
                    },
                }
            },
            motCle: "",
            tabledata: [],
            json_fields: {
                'Date': 'date',
                'N° Ecriture': 'num_ecriture_cd',
                'Compte': 'compte',
                'Tiers/Auxiliaire': 'compte_aux',
                'Libellé': 'libelle',
                // 'Déscription': 'description',
                'Débit': 'montant_debit',
                'Crédit': 'montant_credit',
            },
            json_data: [],
            json_meta: [
                [{
                    'key': 'charset',
                    'value': 'utf-8'
                }]
            ],
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            disabledDates: {
                to: new Date(Date.now())
            },
            DualListSource: [],
            DualListDestination: [],
            date_exercice: {
                date_debut: "",
                date_fin: ""
            },
            afficherToutExercice: "",
            repporanouveau: "true",

        }
    },
    methods: {
        onChangeList: function ({ source, destination }) {
            this.DualListSource = source;
            this.DualListDestination = destination;
            this.filtretexte = JSON.stringify(destination);
        },
        openModal(){
            this.$bvModal.show("crudmodal");
        },
        closeModal(){
            this.$bvModal.hide("crudmodal");
            this.setFilter();
        },
        openFiltreModal(){
            this.filtremodaltitle = "Filtre avancé - Choisir les comptes à afficher";
            this.$bvModal.show("filtremodal");
        },
        getAllCompte() {
            var that = this;
            axios.get(that.base_url+"/journauxdesaisie/getall").then(response => {
                // console.log("===> Source ----");
                that.DualListSource = response.data;
                // that.DualListDestination = response.data;
                // console.log(that.DualListSource);
                // console.log("===> Source ----");
            });

        },
        getDateExercice() {
            var that = this;
            axios.get(that.base_url+"/exercice/get/1").then(response => {
                console.log(response.data);
                that.date_exercice.date_debut = moment(response.data[0].date_debut).format('DD/MM/YYYY');
                that.date_exercice.date_fin = moment(response.data[0].date_fin).format('DD/MM/YYYY');
            });

        },
        // notOverRange(date) {
        //     return date < new Date(this.date_exercice.date_debut) || date > new Date(this.date_exercice.date_fin);
        // },
        changeExercice() {
            if(this.$refs.afficherToutExercice.checked == true) {
                this.filtreDate.dateDebut = this.date_exercice.date_debut;
                this.filtreDate.dateFin = this.date_exercice.date_fin;
            } else {
                this.filtreDate.dateDebut = moment().format('DD/MM/YYYY');
                this.filtreDate.dateFin = moment().format('DD/MM/YYYY');
            }
        },
        closeFiltreModal(){
            this.$bvModal.hide("filtremodal");
            this.setFilter();
        },
        onRowClass(dataItem, index) {
            // put your logic here
            // to reference selectedTo, use `this.$refs.vuetable.selectedTo`
            // and return the CSS class name you want
            // var x = index;
            // console.log("****************************");
            if(index =="") console.log("");
            // console.log(dataItem);
            // console.log("****************************");
            
            return dataItem.brouillard_groupe_id % 2 == 0
              ? 'bg-gray'
              : 'bg-white'
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        onLoading() {
            console.log('loading... show your spinner here');
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            this.moreParams.startDate = this.filtreDate.dateDebut;
            this.moreParams.endDate = this.filtreDate.dateFin;
            this.moreParams.comptes = this.filtretexte;
            this.moreParams.repporanouveau = this.repporanouveau;
            
            Vue.nextTick(() => this.$refs.vuetable.refresh());

            console.log(this.$refs.vuetable);
            console.log(this.moreParams);

            var that =  this;
            axios.post(that.base_url+"/brouillard/fetchallfilter", this.moreParams).then(function(response) {
                console.log("----------");
                console.log(response);
                that.montant_total_debit = response.data[0].total_debit;
                that.montant_total_credit = response.data[0].total_credit;
            });
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            this.moreParams.startDate = this.filtreDate.dateDebut;
            this.moreParams.endDate = this.filtreDate.dateFin;
            this.moreParams.comptes = this.filtretexte;
            this.moreParams.sort = "date,num_ecriture|asc";

            var that =  this;
            axios.post(that.base_url+"/brouillard/fetchalldata", this.moreParams).then(function(response) {
                
                that.tabledata = response.data;
                that.json_data = response.data;
                console.log(that.json_data);

                $("#excel-download").trigger("click");
            
            });
        },
        initListCompte(filtre) {
            //filtre = this.crudform.parent_id;
            axios.get(this.base_url+"/plancomptable/fetchall?sort=num_compte|asc&page=1&fromselect=&per_page=25&filter="+filtre).then(response => {
                this.listeCompte = response.data.data;
                //console.log(this.listeCompte);
            });
        },
        addRow(){
            this.crudmodaltitle = "Ajouter un compte";
            this.crudform = {
                parent_id: "",
                num_compte: "",
                intitule: "",
                auxiliaires: "",
                observations: "",
            }
            this.initListCompte('');
            this.openModal();
        },
        editRow(rowData) {
            this.initListCompte(rowData.debit == "" ? rowData.credit : rowData.debit);
            //alert("You clicked edit on" + JSON.stringify(rowData));
            this.crudform = rowData;
            this.crudmodaltitle = "Editer le brouillard";
            this.openModal();
            
            this.initListCompte(rowData.compte);

            this.crudform.date = moment(rowData.date).format('DD/MM/YYYY');
        },
        listCompteDebitOnChange(item, index) {
            console.log(item);
            console.log(index);

            const result = this.listeCompte.find( ({ id }) => id === item );
            console.log(result);
            
            this.crudform.plan_comptable_id = result.id;
            this.crudform.debit = result.num_compte;
            this.crudform.credit = "";
            this.crudform.description = result.intitule;
            //this.items[index].description = result.intitule;

        },
        listCompteCreditOnChange(item, index) {
            console.log(item);
            console.log(index);

            const result = this.listeCompte.find( ({ id }) => id === item );
            console.log(result);
            
            this.crudform.compte_id = result.id;
            this.crudform.debit = "";
            this.crudform.credit = result.num_compte;
            this.crudform.libelle = result.intitule;
            //this.items[index].description = result.intitule;

        },
        montantOnChange(item) {
            console.log(item);

            this.crudform.montant = item;

            console.log(this.crudform.montant);

        },
        descriptionOnChange(item) {
            console.log(item);

            this.crudform.description = item;

            console.log(this.crudform.description);

        },
        onSubmit() {
            var that =  this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer la modification?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value);
                if(value == true) {
                    axios.post(that.base_url+"/brouillard/edit", $("#formulaire").serialize()).then(function(response) {
                        console.log(response);
                        //this.message_reponse = response.data.msg;
                        if (response.data.error === "") {
                            console.log(response.data.message);
                            that.closeModal();
                            Vue.$toast.open({
                                message: response.data.message,
                                type: 'success',
                                position: 'top-right'
                                // all of other options may go here
                            });
                        } else {
                            console.log("Erreur");
                            Vue.$toast.open({
                                message: response.data.error,
                                type: 'error',
                                position: 'top-right'
                                // all of other options may go here
                            });
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                // An error occurred
            })
            

            
        },
        onSubmitListeCompte() {

            this.setFilter();
            this.closeFiltreModal();
            // var that =  this;
            // axios.post("/brouillard/filtreavance?destination=" + JSON.stringify(this.DualListDestination), $("#formulaireListeCompte").serialize()).then(function(response) {
            //     console.log(response);
            // });

        },
        printBrouillard() {
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            this.moreParams.startDate = this.filtreDate.dateDebut;
            this.moreParams.endDate = this.filtreDate.dateFin;
            this.moreParams.comptes = this.filtretexte;
            
            var that =  this;
            axios.post(that.base_url+"/brouillard/printbd1", this.moreParams).then(function(response) {
                
                that.printPDFValue = response.data;
                console.log(that.base_url+"/"+response.data);
                window.open(that.base_url+"/"+response.data);

            });
        },
        repporanouveauFunc() {
            if(this.$refs.repporanouveaux.checked == true) {
                this.repporanouveau = "true";
            } else {
                this.repporanouveau = "";
            }
        },
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";
        
        /*axios.get("/plancomptable/showrecursive").then(response => {
            this.table = response.data;
        });*/

        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;

        this.getAllCompte();
        this.getDateExercice();
        
        //this.initListCompte('');
    },
    mounted: function() {
        // var that = this;
        this.initListCompte('');
        this.openFiltreModal();

        /*axios.get("https://vuetable.ratiw.net/api/users").then(response => {
            this.fields = response.data.data;
        });*/

        // On change zone de texte
        /*that.moreParams.filter = 'Mot a rechercher';
            Vue.nextTick(() => that.$refs.vuetable.refresh());*/
    }
}